import React from "react";
import styled from "styled-components";

// Components
// Removed BlogBox since it will not be used

export default function Aboutus() {
  return (
    <Wrapper id="about-us">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
          </HeaderInfo>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <Section>
                <h2 className="font20">Welcome to FineChek (A) Systems</h2>
                <p className="font10">
                At FineChek (A) Systems, we are driven by our commitment to excellence in providing Scientific Instruments, 
                industrial laboratory equipment, reagents and consumables.Our mission is to support the scientific 
                and industrial laboratories worldwide by providing reliable,high-quality products that enhance efficiency,
                accuracy, and safety in every operation.
                </p>
              </Section>

              <Section>
                <h2 className="font20">Our Mission</h2>
                <p className="font10">
                Our mission is to empower industries and laboratories with cutting-edge tools and solutions 
                that enable them to perform at their best. We strive to provide products that not only meet
                the current demands of our clients but also anticipate future needs, ensuring long-term value
                and sustainability
                </p>
              </Section>

              <Section>
                <h2 className="font20">What We Do</h2>
                <p className="font10">
                FineChek specializes in the production, and supply of a wide range of scientific and industrial 
                laboratory equipment, as well as high-performance reagents. Our products are integral to various
                sectors, including manufacturing, research and development, quality control, and more. From robust
                industrial machinery to precise laboratory instruments, we offer solutions that are essential for 
                operational success and innovation.
                </p>
              </Section>
              <Section>
                <h2 className="font20">Our Commitment to Quality</h2>
                <p className="font10">
                Quality is the cornerstone of our operations. We are dedicated to maintaining the highest standards 
                in our manufacturing processes, ensuring that every piece of equipment and every reagent we produce 
                is reliable, durable, and effective. Our commitment to quality extends to our customer service, where 
                we aim to provide unparalleled support to help our clients achieve their goals.
                </p>
              </Section>
              <Section>
                <h2 className="font20">Innovation and Excellence</h2>
                <p className="font10">
                Innovation is at the core of FineChek. We continually invest in research and development 
                to stay ahead of technological advancements and industry trends. Our team of experts is passionate about 
                creating innovative solutions that improve efficiency, reduce costs, and contribute to the overall success 
                of our clients. We believe that excellence is achieved through continuous improvement and a dedication 
                to pushing the boundaries of what's possible.
                </p>
              </Section>
              <Section>
                <h2 className="font20">Global Reach, Local Expertise</h2>
                <p className="font10">
                While FineChek operates on a global scale, we understand the importance of catering to the specific needs 
                of local markets. We collaborate closely with our clients to ensure that our products and services are 
                tailored to their unique requirements. Our global reach combined with local expertise allows us to make 
                a significant impact in diverse industries and regions.
                </p>
              </Section>
              <Section>
                <h2 className="font20">Partner with Us</h2>
                <p className="font10">
                At FineChek, we see ourselves as more than just a manufacturer; we are a trusted partner in your success.
                We are committed to providing the tools and support you need to thrive in a competitive environment. 
                Join us on our journey as we continue to innovate, lead, and make a difference in the industrial and 
                laboratory sectors.
                </p>
              </Section>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const HeaderInfo = styled.div`
  text-align: left;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 40px;
  text-align: left;

  h2 {
    margin-bottom: 15px;
  }

  p {
    line-height: 1.6;
  }
`;
