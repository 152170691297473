import { Container } from '@mui/material'
import React from 'react'

const GoogleMap = () => {
  return (
    <Container className='h-full my-4'>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.122574847428!2d36.8208737!3d-1.2747551999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2e19ddcfae2e4211!2sChem-Labs%20Limited!5e0!3m2!1sen!2sus!4v1648390250734!5m2!1sen!2sus" 
            height="450"
            width="1200"
            className='w-full'
            loading="lazy"
            title="Chem-Labs Limited Location">
        </iframe>
    </Container>
  )
}

export default GoogleMap