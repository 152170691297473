import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
// import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Products";
import Blog from "../components/Sections/About Us";
// import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Projects />
      <Blog />
      {/* <Pricing /> */}
      <Contact />
  
      <Footer />
    </>
  );
}


